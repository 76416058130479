import Link from 'next/link'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

const VanillaExpansion = () => (
  <>
    <div className="home-detailed-text-section">
      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >Discover Vanilla Private Servers</h2>
          <div className="detailed-description">
            <p>Classic WoW Private Servers are trending and are very popular. On this page, you can find an updated list of Vanilla WoW Servers, both blizzlike and customized servers that offer you a 1.x experience. Through our Vanilla Server list, you can filter the server-type, population, release status, realm language, realm rates, and more! We have an advanced sorting system, so you can easily find the servers that match the Vanilla Servers you are looking for.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h2 className="zx-text-color-yellow fs-3 mb-2" >The best Vanilla Private Servers in 2024</h2>
          <div className="detailed-description">
          <p>In case you need a break from retail – classic, or you simply don’t/can’t afford a subscription, we have combined a list of the Best Classic WoW Private Servers, for you that is relevant in 2024.</p>
          <p>Based on your needs, if you are searching for a Blizzlike Vanilla Server, you can consider Elysium-project. It is a 1x rated server with around 500-1000 players online. It however has quite a bad reputation, so if you care about that – you might want to avoid Elysium.</p>
          <p>If you are into the end-game of Vanilla, Retro-WoW might be an obvious choice. It is an instant 60 server, so you get a possibility to get straight into the end-game. This way, you avoid having to level up the long road from 1-60, if you don’t enjoy it.</p>
          <p>In case you’re still searching for blizzlike WoW Vanilla Servers but with a modified touch-up, you can consider VanillaPlus. It is a 1X rated Classic Private Server with around 500-1000 players online, and an English speaking server.</p>
          <p>If you’re still in doubt, we recommend you to check through the full list of our combined Vanilla Servers. This way, you can make your own opinion of the servers. We made sure to include as much relevant information as possible, on the Vanilla WoW Servers that are listed. Visitors of Zremax have rated the Vanilla Private Servers they have an opinion about. We hope you will also review the servers you have played on, as it will help other WoW Players decide which Vanilla Server to play on.</p>
          <p>When you are playing on the best server, you want to make sure you have a list of <Link href="/wow/addons/vanilla-addons">Vanilla WoW Addons</Link> downloaded. As a result, you will get the most out of your gameplay. Most of the Vanilla players use Addons so we recommend you do it as well.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h3 className="zx-text-color-yellow fs-3 mb-2" >How do I play on a Vanilla WoW Private Server?</h3>
          <div className="detailed-description">
            <p>Classic-WoW was released in 2019 by Blizzard. After a lot of hype, and petition to make Blizzard actually launch the Classic realms, it finally happened. While it has been a major success, it however appears that many <Link href="/wow/private-servers/">WoW Private Server</Link> players are starting to lose the interest, and go back to Private Servers. Therefore, we made sure to include a decent list of the best Vanilla Private Servers, so you as a player can find the Classic WoW Servers you are looking for.</p>
            <p>Hopefully, you have by now found a WoW Vanilla Server you have found interesting. Afterward, you need to register on their website and get a client, and note down their Realmlist, and you are ready to play Vanilla.<br/>
Alternatively, you can get a <a href="https://zremax.com/blog/vanilla-wow-client-download-1-12-1/">Vanilla WoW Client</a> through us.</p>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col className="mb-4">
          <h3 className="zx-text-color-yellow fs-3 mb-2" >Conclusion</h3>
          <div className="detailed-description">
          <p>Vanilla Private Servers are pretty popular, and we have made a list of updated classic servers, that you can play on. Hopefully, you have now found a Vanilla Server that you consider as the best. We hope you will have a great experience on the server, and gain great moments.</p>
          </div>
        </Col>
      </Row>
    </div>
  </>
)

export default VanillaExpansion
